import type { Deal } from '../core/types/Deal';

export const getPriceFromDeal = (deal: Deal, type: string): string | number | null => {
  const monthlyPrice = deal?.contract
    ? parseFloat(String(deal?.contract?.monthly_price_for_plan || 0)) +
      parseFloat(String(deal?.contract?.monthly_price_for_phone || 0))
    : 0;
  const upfrontPrice = deal?.offer?.price ? parseFloat(deal.offer.price) : 0;

  switch (type) {
    case 'retail':
    case 'upfront':
      return upfrontPrice;
    case 'monthly':
      return monthlyPrice;
    case 'yearly':
      return deal?.contract?.yearly_price ?? 0;
    case 'salePrice':
      if (deal?.contract) {
        return deal.contract?.sale_price ?? monthlyPrice;
      }
      return deal?.offer?.sale_price ?? upfrontPrice;
    case 'total':
      return deal.contract ? monthlyPrice * deal.contract?.length + upfrontPrice : upfrontPrice;
    case 'wasPrice':
      return deal?.offer?.was_price ? parseFloat(String(deal.offer.was_price)) : '';
    default:
      return null;
  }
};
