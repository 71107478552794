import responsiveSites from '../core/modules/sites/data/responsiveSites';
import type { IAddDynamicWidgetsConfig } from '../core/types/AddDynamicWidgets';
import type { PlatformData } from '../core/types/PlatformData';
import getArticleCategory from '../utils/getArticleCategory';

import addComparisonChart from './modules/addComparisonChart';
import addOverlayWidget from './modules/addOverlayWidget';
import addShop from './modules/addShop';
import { buildPlaceholders } from './modules/buildPlaceholders';
import { getVanillaArticleType } from './modules/getVanillaArticleType';
import { hawk } from './modules/hawk';
import type { UnbuiltPlaceholders } from './modules/hawk/processWidgets/model';
import { getArticleType } from './utils/fep';
import getArticleId from './utils/getArticleId';
import getKeywords from './utils/getKeywords';
import { getVanillaTerritory } from './utils/getTerritory';
import getUrlPath from './utils/getUrlPath';

const getPlatformData = async (): Promise<PlatformData> => {
  const keywords = await getKeywords();
  const urlPath = getUrlPath();
  const articleType = getVanillaArticleType(getArticleType(), keywords, urlPath);

  return {
    platform: 'responsive',
    sites: responsiveSites,
    articleType,
    articleCategory: getArticleCategory(keywords),
    territory: getVanillaTerritory(),
    keywords,
    articleId: getArticleId('dimension5'),
  };
};

const addDynamicWidgets = async (
  config: IAddDynamicWidgetsConfig,
): Promise<Array<HTMLElement | null>> => {
  const {
    platformData: { articleType },
  } = config;
  const arrayOfUnbuiltPlaceholders: UnbuiltPlaceholders[] = [];
  // Add Overlay widget on all responsive sites
  addOverlayWidget();

  switch (articleType) {
    case 'deals_compare': {
      const result = await addComparisonChart();
      arrayOfUnbuiltPlaceholders.push(result);
      break;
    }
    case 'shop': {
      const result = await addShop();
      arrayOfUnbuiltPlaceholders.push(result);
      break;
    }
  }

  const unprocessedWidgets = await buildPlaceholders(arrayOfUnbuiltPlaceholders);

  return unprocessedWidgets;
};

hawk(null, getPlatformData, addDynamicWidgets);
