import { Translate } from '../core/types/Localiser';

// Map of non-US dollars to their symbols
const nonUSDollars = { AUD: 'AU$', SGD: 'SG$', CAD: 'CA$', NZD: 'NZ$' };

// List of locales where US dollars should not be displayed as "US$"
const usDollarExclusionLocales = ['en-US', 'en-GB'];

const currencyDisplayMap = {
  USD: 'narrowSymbol',
  GBP: 'narrowSymbol',
  NOK: 'code',
};

const usdIso = 'USD';

export const getFormattedPrice = ({
  price,
  currencyIso,
  type,
  locale,
  translate,
  showPriceRounded,
  useConstFreeText = false,
  showOfferLabel = false,
  label = '',
}: {
  price: string | number | null;
  currencyIso: string;
  type: string;
  locale: string;
  translate: Translate;
  showPriceRounded: string;
  useConstFreeText?: boolean;
  showOfferLabel?: boolean;
  label?: string;
}): string | null => {
  /**
   * - show buy similar label for buysimilars if it is enabled
   * - show a price as 'Free' if it is an upfront and the price is 0
   * - show whatever the price is by default
   */
  if (label && showOfferLabel) {
    return label;
  }
  if (type === 'upfront' && price === 0) {
    if (useConstFreeText) {
      return translate('broadbandCostFree');
    }
    return translate('productCostReactFree');
  }
  if (!price || !currencyIso) {
    return null;
  }

  // Round the price to 0 or 2 decimal places
  const roundedPrice = Number(price).toFixed(showPriceRounded === 'yes' ? 0 : 2);

  const roundedPriceNumber = Number(roundedPrice);

  // Do not show fraction digits when roundedPrice is an integer
  const minimumFractionDigits = Number.isInteger(roundedPriceNumber) ? 0 : 2;

  // Special cases
  const formattedNumber = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits,
  }).format(roundedPriceNumber);

  // Special case for non non-US dollars
  const currencyOverride = nonUSDollars[currencyIso];
  if (currencyOverride) {
    return `${currencyOverride}${formattedNumber}`;
  }

  // Special case for US dollar
  if (currencyIso === usdIso && !usDollarExclusionLocales.includes(locale)) {
    return `US$${formattedNumber}`;
  }

  const currencyDisplay = currencyDisplayMap[currencyIso] || 'symbol';

  // Otherwise use default formatting for given locale
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay,
    minimumFractionDigits,
  }).format(roundedPriceNumber);
};
