import React from 'react';

import { getClickType, ClickType } from '../../../../sharedModules/getClickType';
import getButtonText from '../../../modules/getButtonText';
import type { Deal } from '../../../types/Deal';
import { ArrowRight } from '../ArrowRight';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../TrackedLink/TrackedLink';

interface ButtonProps {
  deal: Deal;
  displayMerchant?: boolean;
  className?: string;
  buttonText?: string;
  clickType?: ClickType;
  customTarget?: TargetEnum;
}

const Button: React.FC<ButtonProps> = ({
  deal,
  displayMerchant,
  className,
  buttonText,
  clickType,
  customTarget,
}) => {
  const {
    buttonType,
    translate,
    genericSharedComponents: { AffiliateLink },
    articleUrl,
  } = useHawkWidgetContext();

  return (
    <AffiliateLink
      deal={deal}
      clickType={
        clickType ||
        getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })
      }
      // We want to track exact model with click
      label={deal?.model_matched?.join(',')}
      {...(buttonType !== 'chevron' ? { className: className || 'button' } : {})}
      customTarget={customTarget}
    >
      {buttonType === 'chevron' ? (
        <ArrowRight />
      ) : (
        buttonText || getButtonText(deal, displayMerchant, translate)
      )}
    </AffiliateLink>
  );
};

export default Button;
