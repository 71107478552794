import React, { useRef, useState } from 'react';

import { ClickType } from '../../../../../sharedModules/getClickType';
import type { Event } from '../../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../../types/FreyrEventPrefix';
import { ProductTypes } from '../../../../../types/ProductType';
import type { Deal } from '../../../../types/Deal';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';
import { VoucherCode } from '../../HawkWidget/model';

import styles from './styles/inputs.css';

interface VultureInputsProps {
  voucherCode?: VoucherCode;
  deal: Deal;
  className?: string;
  uniqueCodeData: string;
}

export const VultureInputs: React.FC<VultureInputsProps> = ({
  voucherCode,
  deal,
  className,
  uniqueCodeData,
}) => {
  const { translate, sendAnalytics, apiCall, loading } = useHawkWidgetContext();
  const isUniqueCodeOffer = deal?.product_type === ProductTypes.unique_code;
  const input = useRef<HTMLInputElement>(null);
  const [codeCopied, setCodeCopied] = useState(false);
  const copyCode = (): void => {
    if (input && input.current) {
      input.current.select();
      // Trigger the copy event (e.g. Ctrl + C)
      document.execCommand('copy');

      setCodeCopied(true);
    }
  };
  const containerStyle = className || 'code';

  const sendClickTypeEvent = (eventText: ClickType): void => {
    if (sendAnalytics) {
      sendAnalytics(
        {
          clickType: eventText,
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event, // TODO: remove after fie-analytics TS is checked
        deal,
      );
    }
  };

  const getInputValue = (): string => {
    if (isUniqueCodeOffer) {
      return loading ? 'Fetching code... please wait' : uniqueCodeData;
    }
    return (voucherCode && voucherCode.display_value) || translate('voucherNoCodeRequired');
  };

  const getCopyClassName = (): string => {
    if (isUniqueCodeOffer) {
      if (!loading && apiCall === 'success') {
        return 'vulture-copybutton';
      }
      return 'disabled';
    }
    return 'vulture-copybutton';
  };

  const getClickHandler = (inputType: ClickType): (() => void) => {
    return () => {
      if (!isUniqueCodeOffer || (!loading && apiCall === 'success')) {
        copyCode();
      }

      sendClickTypeEvent(inputType);
    };
  };
  return (
    <>
      <div className={styles.description}>{translate('voucherInputsDescription')}</div>
      <div className={styles[containerStyle]}>
        <input
          {...{
            type: 'input',
            value: getInputValue(),
            readOnly: true,
            ref: input,
            className:
              voucherCode || uniqueCodeData ? styles['input-code'] : styles['input-discount'],
            onClick: getClickHandler(ClickType.INPUT_FIELD),
          }}
        />
        {(voucherCode || uniqueCodeData) && deal && (
          <div
            className={styles[getCopyClassName()]}
            onClick={getClickHandler(ClickType.COPY_CODE)}
            role="button"
            tabIndex={0}
            onKeyDown={getClickHandler(ClickType.COPY_CODE)}
          >
            {translate(codeCopied ? 'voucherCodeCopied' : 'voucherCodeCopy')}
          </div>
        )}
      </div>
    </>
  );
};
