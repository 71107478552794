import { ProductTypes } from '../../../../../../types/ProductType';
import { Promo } from '../../../../../types/Deal';
import { VoucherCode } from '../../../HawkWidget/model';

export const isVoucherCodeExpectedForProductType = (productType: ProductTypes): boolean => {
  const nonVoucherCodeProducts = [ProductTypes.unique_code, ProductTypes.rewards];
  return !nonVoucherCodeProducts.includes(productType);
};

export const getVoucherCode = (productType: ProductTypes, promos?: Promo[]): VoucherCode => {
  const voucherCodePromoType = 'voucher_code';
  if (promos && isVoucherCodeExpectedForProductType(productType)) {
    return promos.find((promo) => promo.type === voucherCodePromoType) || null;
  }

  return null;
};
