import React, { useState } from 'react';

import useDealCountdownTimeString from '../../DealCountdown/useDealCountdownTimeString';

import { getVoucherCode } from './modules/voucherCode';
import { VultureRevealContextProps } from './types';
import { VulturePopup } from './VulturePopup';

export const VultureRevealContext: React.FC<VultureRevealContextProps> = (props) => {
  const {
    params,
    visibleMatchId,
    dealData,
    genericSharedComponents: { HawkWidgetAdvanced },
    defaultTab,
    translate,
    language,
    area,
    children,
  } = props;
  const dealType = defaultTab && defaultTab.value;

  const dealStatus =
    dealData.deals[0].start_time &&
    dealData.deals[0].end_time &&
    useDealCountdownTimeString(
      dealData.deals[0].start_time,
      dealData.deals[0].end_time,
      translate,
      language,
      area,
    );

  // show expired version:
  // if data-deal-end-time-to: "now" is in the snippet e.g vulture expired offers section
  // or if match-id provided in the snippet and deal.end_time is expired
  const expiredDealTime =
    params &&
    dealType &&
    params[dealType] &&
    (params[dealType].deal_end_time_to ||
      (params[dealType].match_id && dealStatus && dealStatus.status === 'expired'));
  const dealWithUrlMatchId = dealData?.deals?.find((deal) => {
    return deal?.match_id === Number(visibleMatchId);
  });
  const [uniqueCodeData, setUniqueCodeData] = useState('');
  const [popupDeal, setPopupDeal] = useState(dealWithUrlMatchId || null);
  const [isPopupRevealed, setIsPopupRevealed] = useState(false);
  const voucherCode = popupDeal ? getVoucherCode(popupDeal.product_type, popupDeal.promos) : null;
  const [apiCall, setApiCall] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <HawkWidgetAdvanced
      {...{
        ...props,
        popupDeal,
        setPopupDeal,
        isPopupRevealed,
        setIsPopupRevealed,
        voucherCode,
        uniqueCodeData,
        setUniqueCodeData,
        apiCall,
        setApiCall,
        loading,
        expiredDealTime,
        setLoading,
      }}
    >
      {children}
      <VulturePopup />
    </HawkWidgetAdvanced>
  );
};
