export enum CookieKeys {
  GA = '_ga',
  KIOSQ = 'kiosq_user',
}

export const getCookie = (cookieKey: string): string => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieKey}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift() ?? '';
  }
  return '';
};
