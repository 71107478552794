import React, { useEffect } from 'react';

import { ClickType } from '../../../../../../../sharedModules/getClickType';
import { getButtonClassName } from '../../../../../../../sharedModules/vultureStyling/styling';
import { ProductTypes } from '../../../../../../../types/ProductType';
import { DialogOverlay } from '../../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../../../../TrackedLink/TrackedLink';
import { VultureInputs } from '../../../Inputs';
import { VultureTermsConditions } from '../../../TermsConditions';

import { loadUniqueCode } from './modules/loadUniqueCode';
import styles from './styles/reveal.css';

export const VultureReveal: React.FC = () => {
  const {
    popupDeal,
    setPopupDeal,
    voucherCode,
    translate,
    endpoint,
    site,
    area,
    uniqueCodeData,
    setIsPopupRevealed,
    setUniqueCodeData,
    setApiCall,
    setLoading,
    genericSharedComponents: { Button, DealImage, DisplayName },
  } = useHawkWidgetContext();

  setIsPopupRevealed(true);
  useEffect(() => {
    if (popupDeal?.product_type === ProductTypes.unique_code) {
      loadUniqueCode(
        setLoading,
        setApiCall,
        setUniqueCodeData,
        translate,
        endpoint,
        site,
        area,
        popupDeal,
      );
    }
  }, [popupDeal]);

  if (popupDeal) {
    const merchantName = popupDeal.merchant && popupDeal.merchant.name;

    if (merchantName) {
      const handleCloseDialog = (): void => {
        setPopupDeal(null);
      };

      const buttonText =
        popupDeal.product_type === ProductTypes.rewards
          ? `Activate Reward${
              popupDeal.merchant.name ? ` and go to ${popupDeal.merchant.name}` : ''
            }`
          : translate(
              'voucherMerchantMessage',
              popupDeal.merchant.name ? [popupDeal.merchant.name] : [],
            );

      const buttonClassName = getButtonClassName(
        popupDeal.product_type,
        !!voucherCode || !!uniqueCodeData,
      );

      return (
        <DialogOverlay closeDialog={handleCloseDialog}>
          <div className={styles.container}>
            <div className={styles.main}>
              <DealImage
                deal={popupDeal}
                priority={['merchant']}
                className="vulture"
                width={120}
                height={95}
              />
              {popupDeal.product_type === ProductTypes.rewards ? (
                <>
                  <p className={styles['display-name']}>
                    To activate your reward, make a purchase with {popupDeal.merchant.name}
                  </p>
                  <p className={styles.text}>
                    Clicking the Activate Reward button will take you to the{' '}
                    {popupDeal.merchant.name} site and allow us to track your purchase
                  </p>
                </>
              ) : (
                <>
                  <DisplayName deal={popupDeal} type="product" className={styles['display-name']} />
                  <VultureInputs
                    voucherCode={voucherCode}
                    deal={popupDeal}
                    uniqueCodeData={uniqueCodeData}
                  />
                </>
              )}
              <Button
                deal={popupDeal}
                className={buttonClassName}
                displayMerchant={true}
                buttonText={buttonText}
                clickType={ClickType.GO_TO_RETAILER}
                customTarget={TargetEnum.BLANK}
              />
            </div>
            {popupDeal && popupDeal.offer && popupDeal.offer.product_note && (
              <div className={styles.terms}>
                <VultureTermsConditions deal={popupDeal} />
              </div>
            )}
          </div>
        </DialogOverlay>
      );
    }
  }
  return null;
};
