import type { Deal } from '../types/Deal';

export enum PriceType {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  RETAIL = 'retail',
}

export default (deal: Deal): string => {
  if (deal?.contract?.yearly_price && deal.contract.yearly_price !== 0.0) {
    return PriceType.YEARLY;
  }
  if (deal?.contract?.monthly_price && deal.contract.monthly_price !== '0.00') {
    return PriceType.MONTHLY;
  }
  return PriceType.RETAIL;
};
