import * as React from 'react';

import { sendFreyrEvent, sendGAEvent } from '../../../analytics-library/entry';
import getTime from '../../../utils/getTime';
import { getHawk } from '../../../utils/global';
import type { DealData } from '../../types/DealData';

import { calculateViewportTime } from './calculateViewportTime';
import getData from './getData';
import getLinkElement from './getLinkElement';
import { AnalyticsAttributes } from './model';
import type { IAnalytics, IAnalyticsMethods, IUseAnalytics } from './model';
import postProcessWidget from './postProcessWidget';

export default (props): IUseAnalytics => {
  const analytics = React.useRef<IAnalytics>({
    [AnalyticsAttributes.PLACEHOLDER]: props.placeholder,
    [AnalyticsAttributes.WIDGET_ID]: props.widgetId,
    [AnalyticsAttributes.WIDGET]: props.widget,
    [AnalyticsAttributes.SITE]: props.site,
    [AnalyticsAttributes.TYPE]: props.type,
    [AnalyticsAttributes.WIDGET_TYPE_COMPONENT]: props.widgetTypeComponent,
    [AnalyticsAttributes.DATA]: props.data,
    [AnalyticsAttributes.DEFAULT_TAB]: props.defaultTab,
    [AnalyticsAttributes.GET_DEAL_DATA]: props.getDealData,
    [AnalyticsAttributes.EDITORIAl]: props.editorial,
    [AnalyticsAttributes.ARTICLE_ID]: props.articleId,
    [AnalyticsAttributes.REVIEWS]: props.reviews ?? [],
    [AnalyticsAttributes.DEAL_DATA]: props.dealData ?? null,
    [AnalyticsAttributes.BATTLE]: props.battle ?? null,
    [AnalyticsAttributes.LANGUAGE]: props.language ?? 'en-GB',
    [AnalyticsAttributes.NAME]: props.name ?? null,
    [AnalyticsAttributes.STAR]: props.star ?? null,
    [AnalyticsAttributes.AREA]: props.area ?? null,
    [AnalyticsAttributes.SEND_VIEWED_EVENT]: false,
    [AnalyticsAttributes.WIDGET_VIEWED]: false,
    [AnalyticsAttributes.VIEWPORT_TIME]: 0,
    [AnalyticsAttributes.VIEWPORT_ENTER_TIME]: null,
  });

  const inFocusTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [inFocus, setInFocus] = React.useState<boolean>(props.inFocus);

  const sendAnalytics: IAnalyticsMethods['sendAnalytics'] = React.useCallback(
    (event, deal = null, uiEvent = null, product = null) => {
      const dealData = analytics.current.dealData ?? {
        deals: [],
        totalDeals: 0,
        dealType: 'deals',
        modelInfo: {},
        link_text: '',
        url: '',
      };
      const { deals } = dealData;
      const { skipGA } = getHawk();
      if (deal && deals.length > 0) {
        event.index = deals.findIndex((dealObj) => dealObj.product_key === deal?.product_key);
      }

      const currentAnalytics = {
        widgetId: analytics.current.widgetId,
        editorial: analytics.current.editorial,
        name: analytics.current.name,
        articleId: analytics.current.articleId,
        reviews: analytics.current.reviews,
        battle: analytics.current.battle,
        placeholder: analytics.current.placeholder,
        language: analytics.current.language,
        star: analytics.current.star,
        widget: analytics.current.widget,
        viewportEnterTime: analytics.current.viewportEnterTime,
        viewportTime: analytics.current.viewportTime,
      };

      if (analytics.current.site.ga) {
        sendGAEvent(
          getData(event, product, dealData, deal, currentAnalytics),
          analytics.current.site.ga,
          uiEvent ? getLinkElement(uiEvent.target as HTMLElement) : null,
          skipGA,
          false,
        );
      }

      sendFreyrEvent(getData(event, product, dealData, deal, currentAnalytics), skipGA);

      if (event.type === 'viewed') {
        analytics.current[AnalyticsAttributes.SEND_VIEWED_EVENT] = false;
      }
    },
    [],
  );

  const getAnalyticsData: IAnalyticsMethods['getAnalyticsData'] = React.useCallback(() => {
    let dealData = analytics.current[AnalyticsAttributes.DEAL_DATA];
    if (!dealData) {
      dealData = {
        deals: [],
        totalDeals: 0,
        dealType: 'deals',
        modelInfo: {},
      };
    }

    return getData(null, null, dealData, null, analytics.current);
  }, []);

  const setDealData: IAnalyticsMethods['setDealData'] = React.useCallback((dealData: DealData) => {
    analytics.current[AnalyticsAttributes.DEAL_DATA] = dealData;
  }, []);

  const onViewportEnter: IAnalyticsMethods['onViewportEnter'] = React.useCallback(() => {
    if (!analytics.current.widgetViewed) {
      analytics.current[AnalyticsAttributes.VIEWPORT_ENTER_TIME] = getTime();
      analytics.current[AnalyticsAttributes.WIDGET_VIEWED] = true;
      analytics.current[AnalyticsAttributes.SEND_VIEWED_EVENT] = true;

      postProcessWidget({
        sendAnalytics,
        setDealData,
        placeholder: analytics.current && analytics.current.placeholder,
        widgetId: analytics.current.widgetId,
        sendViewedEvent: analytics.current.sendViewedEvent,
        type: analytics.current.type,
        widgetTypeComponent: analytics.current.widgetTypeComponent,
        dealData: props.dealData,
        data: analytics.current.data,
      });
    }

    if (!inFocus && analytics.current.viewportTime > props.inFocusDuration) {
      setInFocus(true);
    } else if (analytics.current.viewportTime < props.inFocusDuration) {
      const timeToTriggerFocus = props.inFocusDuration - analytics.current.viewportTime;

      inFocusTimeoutRef.current = setTimeout(() => {
        setInFocus(true);
      }, timeToTriggerFocus);
    }
  }, [inFocus]);

  const onViewportLeave: IAnalyticsMethods['onViewportLeave'] = React.useCallback(() => {
    analytics.current[AnalyticsAttributes.VIEWPORT_TIME] = calculateViewportTime(
      analytics.current.viewportEnterTime || getTime(),
      analytics.current.viewportTime || 0,
    );

    if (inFocusTimeoutRef.current) {
      clearTimeout(inFocusTimeoutRef.current);
      inFocusTimeoutRef.current = null;
    }
  }, []);

  const modifiedGetDealData: IAnalyticsMethods['modifiedGetDealData'] = React.useCallback(
    (data, overrides) => {
      const dealData = props.getDealData(data, overrides);

      setDealData(dealData);

      return dealData;
    },
    [props.getDealData],
  );

  return {
    analytics,
    setDealData,
    sendAnalytics,
    getAnalyticsData,
    onViewportEnter,
    onViewportLeave,
    modifiedGetDealData,
    inFocus,
  };
};
